export default [
  {
    header: 'Home',
    icon: 'HomeIcon',
    key: 'HomeKey'
  },
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    header: 'suits',
    icon: 'FileIcon',
  },
  {
    title: 'suits-all',
    route: 'suits-all',
    icon: 'TargetIcon',
  },
  {
    title: 'suits-monitoring',
    route: 'suits-monitoring',
    icon: 'EyeIcon',
  },
  {
    title: 'suits-interaction',
    route: 'suits-interaction',
    icon: 'fa-regular fa-handshake',
    iconType: 'font-awesome',
  },
  {
    title: 'suits-managed',
    route: 'suits-managed',
    icon: 'ActivityIcon',
  },

  {
    header: 'configurations',
    icon: 'SettingsIcon',
  },
  {
    title: 'users',
    route: 'users',
    icon: 'UsersIcon',
  },
  {
    title: 'config-points',
    route: 'config-points',
    icon: 'ThermometerIcon',
  },
  {
    title: 'about',
    route: 'config-about',
    iconType: 'font-awesome',
    icon: 'fa-regular fa-circle-question',
  }
]
